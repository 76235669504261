// Stylesheets
import './main.scss';

import './resources/images/favicon.png';

// Imports all component based scss files.
import './components/**/*.scss';
// import favicon here.

interface AAAEM {
  isMobile: boolean;
  isTouch: boolean;
  isAuthorMode: boolean,
  env: string,
  browser: {
    name: string;
    version: string;
  },
  // All utility functions can be found here: https://gitlab.com/venture-moonwalker/aaaem/aaaem-common/-/blob/645c2f4310c2cf0864454035850a95d28e65e68e/ui.frontend/src/global.ts
  utilities: any;
  plugins: {
    tinySlider: any;
    badgerAccordion: any;
    tabbyJs: any;
    plyr: any;
  }
}

declare global {
  interface Window {
    AAAEM: AAAEM;
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed 
    _badgerInstances: any; //accordion
  }
}

(() => {
  const init = () => {
    if (window.Bus) {
      // listen to events here
    }
  };
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})(); 